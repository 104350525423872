/*----------------------------------------*/
/*  13 - Components - Navigation
/*----------------------------------------*/
/* ---Mobile Menu Wrapper--- */
.mobile-menu_wrapper,
.offcanvas-search_wrapper {
	.offcanvas-menu-inner {
		position: fixed;
		top: 0;
		right: -285px;
		width: 285px;
		height: 100%;
		background: rgba(255, 255, 255, 0.95);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
		z-index: 9999;
		visibility: hidden;
		@extend %basetransition;
		@include breakpoint (max-x_small) {
			width: 270px;
		}
		// Offcanvas Inner Search Bar
		.offcanvas-inner_search {
			position: absolute;
			z-index: 9;
			top: 0;
			left: 0;
			width: calc(100% - 0px);
			padding: 10px;
			background-color: $border-color;
			.hm-searchbox {
				position: relative;
				> input {
					background-color: $border-color;
					border: 0;
					height: 40px;
					line-height: 40px;
					width: 100%;
					padding: 0 52px 0 15px;
				}
				> .search_btn {
					background: transparent;
					color: $body-text_color;
					position: absolute;
					top: 10px;
					right: 20px;
					border: 0;
					font-size: 24px;
					&:hover {
						@include breakpoint(max-medium) {
							color: $primary-color;
						}
					}
				}
			}
		}
	}
	&.open {
		.offcanvas-menu-inner {
			left: auto;
			right: 0;
			visibility: visible;
			padding: 105px 0 0;
		}
	}
}
/* ---Offcanvas Close Button--- */
.btn-close {
	position: absolute;
	top: 0;
	left: -60px;
	background: $heading-color;
	width: 60px;
	height: 60px;
	line-height: 63px;
	text-align: center;
	color: $bg-white_color;
	z-index: 10;
	font-size: 24px;
	@extend %basetransition;
	padding: 0;
	border-radius: 0;
	opacity: 1;
	&:focus{
		outline: 0;
	}
	@include breakpoint (xx-small) {
		left: -50px;
		width: 50px;
	}
	&:hover {
		background-color: $primary-color;
		opacity: 1;
		> i {
			transform: rotate(90deg);
			color: $bg-white_color !important;
		}
	}
	> i {
		transform: rotate(0);
		@extend %basetransition;
		display: block;
	}
}
/* ---Offcanvas Navigation Area--- */
.offcanvas-navigation {
	.mobile-menu {
		overflow-y: auto;
		min-height: 165px;
		margin-right: -25px;
		padding-right: 25px;
		& > li {
			height: 100%;
			& > a {
				span {
					position: relative;
					font-weight: 600;
				}
			}
		}
		li {
			position: relative;
			border-bottom: 1px solid $border-color;
			&:last-child {
				border-bottom: 0;
			}
			&:hover {
				> a {
					color: $primary-color;
				}
				> span {
					color: $primary-color;
				}
			}
			& > .menu-expand {
				position: absolute;
				right: 0;
				top: 2px;
				width: 50px;
				height: 100%;
				line-height: 40px;
				cursor: pointer;
				text-align: center;
				margin-right: 4px;
				@extend %basetransition;
			}
			a {
				font-size: 14px;
				font-weight: 400;
				text-transform: uppercase;
				display: block;
				padding: 10px 0;
			}
			.sub-menu {
				padding-left: 10px;
				li {
					a {
						text-transform: capitalize;
						font-size: 13px;
					}
				}
			}
			&.menu-open {
				& > .menu-expand {
					i {
						&:before {
							content: '\f123';
							font-family: "Ionicons";
						}
					}
				}
			}
		}
	}
}
/* ---Header User Setting Area--- */
.user-setting_area {
	padding-top: 25px;
	> ul {
		> li {
			> a {
				> span {
					text-transform: capitalize;
				}
			}
		}
	}
}

/* ---Offcanvas Search Area--- */
.offcanvas-search_wrapper {
	.offcanvas-menu-inner {
		background-color: $bg-nero_color;
		opacity: 0.97;
		width: 100%;
		right: 0;
		@extend %basetransition;
		transform: scale(0);
		visibility: visible;
		.btn-close {
			background: transparent;
			color: $body-text_color;
			top: 15px;
			right: 15px;
			left: auto;
			font-size: 45px;
			color: $bg-white_color;
			> i {
				&:hover {
					color: $primary-color !important;
				}
			}
		}
		.offcanvas-search {
			background-color: $border-color;
			transform: translate(-50%, -50%);
			position: absolute;
			z-index: 9;
			top: 50%;
			left: 50%;
			width: calc(100% - 25%);
			.hm-searchbox {
				position: relative;
				> input {
					background-color: $heading-color;
					border: 0;
					height: 100px;
					line-height: 100px;
					width: 100%;
					padding: 0 100px 0 25px;
					color: $bg-white_color;
					&::placeholder {
						color: $bg-white_color;
					}
				}
				> .search_btn {
					position: absolute;
					font-size: 48px;
					top: 50%;
					transform: translateY(-50%);
					right: 30px;
					border: 0;
					background: transparent;
					color: $bg-white_color;
					&:hover {
						color: $primary-color;
					}
				}
			}
		}
	}
	&.open {
		.offcanvas-menu-inner {
			left: auto;
			right: 0;
			padding: 0;
			transform: scale(1);
			visibility: visible;
		}
	}
}

/* ---Category Menu--- */
.category-menu {
	position: relative;
	@include breakpoint (max-medium) {
		width: 100%;
	}
	.category-heading {
		background: $primary-color;
		position: relative;
		cursor: pointer;
		padding: 22px 0;
		border-radius: 25px 25px 0 0;
		> h2 {
			margin: 0;
			padding: 0;
			font-size: 16px;
			&:after {
				content: "\f03c";
				font-family: 'Font Awesome 5 Free';
				font-weight: 600;
				position: absolute;
				top: 22px;
				left: 20px;
				@include breakpoint (normal) {
					left: 10px;
				}
			}
			> span {
				padding-left: 50px;
				font-weight: 600;
				@include breakpoint (max-normal) {
					padding-left: 40px;
				}
				@include breakpoint (normal) {
					padding-left: 30px;
				}
			}
		}
	}
	.category-menu-list {
		background: $bg-white_color;
		border: 2px solid $primary-color;
		border-radius: 0 0 25px 25px;
		padding: 0 20px;
		position: absolute;
		left: 0;
		top: calc(100% - 1px);
		display: block;
		width: 100%;
		z-index: 99;
		@include breakpoint (normal) {
			padding: 0 15px;
		}
		> ul {
			> li {
				position: relative;
				border-bottom: 1px solid $border-color;
				> a {
					display: block;
					text-transform: capitalize;
					overflow: hidden;
					font-size: 14px;
					font-weight: 600;
					padding: 10px 0;
				}
				&:hover {
					.cat-mega-menu,
					.cat-dropdown_menu {
						opacity: 1;
						-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
						visibility: visible;
					}
					> a {
						color: $primary-color;
					}
				}
				&.right-menu {
					> a:after {
						content: '\f054';
						font-family: 'Font Awesome 5 Free';
						font-weight: 600;
						@extend %basetransition;
						font-size: 12px;
						position: absolute;
						top: 15px;
						right: 0;
					}
				}
				&.rx-child {
					display: none;
				}
				&.rx-parent {
					cursor: pointer;
					a.rx-show {
						display: none;
					}
					&.rx-change a {
						&.rx-default {
							display: none;
						}
						&.rx-show {
							display: block;
						}
					}
				}
			}
		}
	}
}
// Category Mega menu One
.cat-mega-menu {
	background: $bg-white_color;
	position: absolute;
	top: 0;
	left: 110%;
	z-index: 999;
	width: 220px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility: hidden;
	@extend %basetransition;
	width: 640px;
	padding: 30px;
	overflow: hidden;
	@include breakpoint (max-medium) {
		position: static;
		z-index: 9;
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		visibility: visible;
		background-color: transparent;
		padding: 0;
		box-shadow: none;
		border: 0;
		width: 100%;
		transition: none;
	}
	@include breakpoint (normal) {
		left: 108%;
	}
	> li {
		&.right-menu {
			width: 33.333%;
			float: left;
		}
		&.cat-mega-title {
			> a {
				font-weight: 600;
				text-transform: uppercase;
				font-size: 16px;
				margin-bottom: 15px;
				display: block;
				line-height: 20px;
				position: relative;
				&:hover {
					color: $bg-white_color;
				}
			}
		}
		> ul {
			> li {
				> a {
					font-size: 14px;
					display: block;
					line-height: 30px;
					font-weight: 400;
					color: $dim-gray_color;
					text-transform: capitalize;
					&:hover {
						color: $primary-color-4;
					}
				}
			}
		}
	}
	// Category Mega menu Two
	&.cat-mega-menu-2 {
		width: 840px;
		@include breakpoint (normal) {
			width: 740px;
		}
		@include breakpoint (max-medium) {
			width: auto;
		}
		> li {
			&.right-menu {
				width: 25%;
				@include breakpoint (max-medium) {
					width: 100%;
				}
			}
		}
	}
	// Category Mega menu Three
	&.cat-mega-menu-3 {
		width: 440px;
		@include breakpoint (max-medium) {
			width: auto;
		}
		> li {
			&.right-menu {
				width: 50%;
				@include breakpoint (max-medium) {
					width: 100%;
				}
			}
		}
	}
}

/* ---Category Dropdown Menu--- */
.cat-dropdown_menu {
	border: 1px solid $border-color;
	position: absolute;
	top: 0;
	left: 110%;
	background: $bg-white_color;
	z-index: 999;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility: hidden;
	@extend %basetransition;
	width: 205px;
	padding: 0 30px;
	overflow: hidden;
	> li {
		> a {
			border-bottom: 1px solid $border-color;
			padding: 10px 0;
			display: block;
		}
	}
	&.cat-dropdown_menu-2 {
		width: 320px;
	}
}

/* ---Category Menu In Mobile Device--- */
@include breakpoint (max-medium) {
	.category-menu-list > ul > li.right-menu > a:after {
		display: none;
	}
	.category-menu-list > ul > li i {
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
		height: 50px;
		width: 70px;
		line-height: 50px;
		z-index: 9;
		display: block;
	}
	.category-menu-list > ul > li i:before {
		background-color: $bg-zambezi_color;
		width: 8px;
		height: 2px;
		content: "";
		position: absolute;
		left: 50%;
		margin-left: -3px;
		margin-top: -1px;
		top: 50%;
	}
	.category-menu-list > ul > li i:after {
		background-color: $bg-zambezi_color;
		width: 2px;
		height: 8px;
		content: "";
		position: absolute;
		left: 50%;
		margin-top: -4px;
		margin-left: 0;
		top: 50%;
		transition: all 0.3s ease 0s;
		transform: scaleY(0);
	}
	.category-menu-list > ul > li i.menu-expand.active:after {
		background-color: transparent;
	}
	.category-menu-list > ul > li i.expand:after {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
	li.right-menu .cat-mega-menu > li {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
		padding: 0;
		position: relative;
	}
	.cat-mega-menu > li.cat-mega-title > a:after {
		display: none;
	}
	.cat-mega-menu > li.cat-mega-title > a,
	.cat-mega-menu > li > ul > li > a,
	.cat-dropdown > li > a {
		padding: 10px 25px 10px 35px;
		font-size: 13px;
		color: $heading-color;
		font-weight: normal;
		position: relative;
		margin: 0;
		display: block;
		text-transform: inherit;
	}
	.cat-mega-menu > li.cat-mega-title:last-child > a,
	.cat-mega-menu > li > ul > li:last-child > a,
	.cat-dropdown > li:last-child > a {
		border-bottom: 0;
	}
}
